<template>
    <sections-visual-text class="login">
        

        <template #headline>Anmelden</template>

        <template #content>
            <FormKit
                id="login"
                v-model="formData"
                type="form"
                :submit-attrs="{ inputClass: 'butty accent' }"
                submit-label="Anmelden"
                :errors="errors"
                @submit="handleSubmit"
            >
                <div class="row">
                    <FormKit
                        wrapper-class="required"
                        type="email"
                        name="email"
                        label="E-Mail"
                        placeholder=""
                        validation="required|email"
                    />
                </div>
                <div class="row">
                    <FormKit
                        wrapper-class="required"
                        type="password"
                        name="password"
                        label="Passwort"
                        placeholder=""
                        validation="required"
                        validation-visibility="blur"
                    >
                        <template #help>
                            <linky
                                text-link
                                :to="{ name: 'reset-password' }"
                                class="reset-password"
                            >
                                Passwort vergessen?</linky
                            >
                        </template>
                    </FormKit>
                </div>
            </FormKit>
        </template>
    </sections-visual-text>
</template>

<script>
import useUserStore from '~/stores/user.js'

export default {
    inject: ['showModal', 'loading'],

    async setup() {
        const [userStore, i18n] = await Promise.all([
            useUserStore(),
            useI18n(),
            useHead({
                title: `Anmelden`,
                // meta: [
                //     {
                //         name: 'description',
                //         content: ``,
                //     },
                // ],
            }),
        ])

        return {
            userStore,
            i18n,
        }
    },

    data() {
        return {
            formData: {},
            errors: [],
        }
    },

    computed: {
        destination() {
            return (
                this.$route.query.redirect || {
                    name: 'user-dashboard',
                }
            )
        },
    },

    created() {
        if (this.userStore.authenticated) {
            this.$router.push(this.destination)
            return
        }

        this.userStore.checkAuthentication().then((value) => {
            if (value) {
                this.$router.push(this.destination)
            }
        })
    },

    methods: {
        handleSubmit() {
            if (this.loading()) {
                return
            }

            this.errors.length = 0
            this.loading(true)

            this.userStore
                .login(this.formData.email, this.formData.password)
                .then((userId) => {
                    this.$router.push(this.destination)
                    this.$tracker.emit('user.login', { clientId: userId })
                })
                .catch((error) => {
                    console.warn('login failed', error)

                    this.$bugsnag.notify(error, (event) => {
                        event.context = 'login.submit'
                        event.addMetadata('email', this.formData.email)
                    })

                    this.$formkit.reset('login', { email: this.formData.email })

                    let message = error.message
                    if (this.i18n.login.errorMessages[message]) {
                        message = this.i18n.login.errorMessages[message]
                    }

                    this.errors.push(message)
                })
                .finally(() => {
                    this.loading(false)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.login {
    :deep(.content) {
        .formkit-form {
            .formkit-wrapper {
                display: flex;
                flex-flow: column nowrap;
            }

            .reset-password {
                display: inline-block;
                margin-top: 0.25rem;
                @apply text-xs;

                &:not(:hover) {
                    color: theme('colors.slate.400');
                    --tl-bg-color: theme('colors.slate.200');
                }
            }

            .butty {
                margin: 0 0 0 auto;
            }
        }

        .formkit-actions {
            margin-top: 0.5rem;
        }
    }
}

.row {
    display: flex;
    flex-flow: row nowrap;

    :deep(.formkit-outer) {
        flex: auto 1 1;
        width: 50%;

        .formkit-wrapper {
            max-width: none;
        }
    }
}

@screen sm {
    :deep(.section-visual-inner) {
        header,
        .content {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@screen md {
    :deep(.section-visual-inner) {
        header,
        .content {
            width: auto;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
</style>
